.catalogue {
  &-item {
    /* border-bottom: 1px solid $grey-2; */
    color: $default-color;
    display: block;
    padding: 1rem 0;
    font-family: $vikki-custom-font1;

    &:hover .catalogue-line,
    &:focus .catalogue-line {
      width: 5rem;
    }

    &:last-child {
      border: 0;
    }
  }

  &-time {
    color: $default-tint;
    font-family: $serif-secondary;
    letter-spacing: .5px;
  }

  &-title {
    color: $vikki-shade2-light;
    display: block;
    font-family: $sans-serif;
    font-size: 1rem;
    font-weight: 700;
    margin: .5rem 0;
  }

  &-line {
    @include transition(all .3s ease-out);
    border-top: .2rem solid $default-shade;
    display: block;
    width: 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .catalogue {
    &-item {
      /* border-bottom: 1px solid $grey-2; */
      color: $default-color;
      display: block;
      padding: 1rem 0;
      font-family: $vikki-custom-font1;
  
      &:hover .catalogue-line,
      &:focus .catalogue-line {
        width: 5rem;
      }
  
      &:last-child {
        border: 0;
      }
    }
  
    &-time {
      color: $default-tint;
      font-family: $serif-secondary;
      letter-spacing: .5px;
    }
  
    &-title {
      color: $vikki-shade2-dark;
      display: block;
      font-family: $sans-serif;
      font-size: 1rem;
      font-weight: 700;
      margin: .5rem 0;
    }
  
    &-line {
      @include transition(all .3s ease-out);
      border-top: .2rem solid $default-shade;
      display: block;
      width: 2rem;
    }
}
}