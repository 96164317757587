// Colors
$default-color: #555;
$vikki-color: #070707;
$default-shade: #353535;
$vikki-shade: #396dce;
//$vikki-shade2: #F24784;
$vikki-shade2-dark: #008E76;
$vikki-shade2-light: #030303;
$default-tint: #aaa;
$grey-1: #979797;
$grey-2: #e5e5e5;
$grey-3: #f9f9f9;
$white: #fff;
$blue: #4a9ae1;
$shadow-color: rgba(0, 0, 0, .2);
$code-color: #bf616a;
$vikki-dark-bg: #030303;
$vikki-light-bg: #fff;
$vikki-dark-default-color: #C4C4C4;
$vikki-light-default-color: #555;
$vikki-dark-grey-3: #201c1c;

// Fonts
$serif-primary: 'Libre Baskerville', 'Times New Roman', Times, serif;
$serif-secondary: Palatino, 'Palatino LT STD', 'Palatino Linotype', 'Book Antiqua', 'Georgia', serif;
$sans-serif: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
$monospaced: Menlo, Monaco, monospace;
$vikki-custom-font1: 'Roboto', sans-serif;

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
     -moz-box-sizing: $type;
          box-sizing: $type;
}

@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
          transition: $args;
}
