.container {
  margin: 0 auto;
  max-width: 920px;
  width: 90%;
}

main,
footer,
.nav-container {
  display: block;
  margin: 0 auto;
  max-width: 920px;
  width: 90%;
}

.nav {
  box-shadow: 0 2px 2px -2px $shadow-color;
  overflow: auto;
  background-color: $vikki-light-bg;

  &-container {
    margin: 1rem auto;
    position: relative;
    text-align: center;
  }

  &-title {
    @include transition(all .2s ease-out);
    color: $vikki-shade2-light;
    display: inline-block;
    margin: 0;
    padding-right: .2rem;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }

  ul {
    list-style-type: none;
    margin: 1rem 0 0;
    padding: 0;
    text-align: center;
  }

  li {
    @include transition(all .2s ease-out);
    color: $default-color;
    display: inline-block;
    opacity: .6;
    padding: 0 2rem 0 0;

    &:last-child {
      padding-right: 0;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  a {
    color: $default-color;
    font-family: $sans-serif;
  }
}

@media (prefers-color-scheme: dark) {
  .nav {
    background-color: $vikki-dark-bg;
    &-title {
      @include transition(all .2s ease-out);
      color: $vikki-shade2-dark;
      display: inline-block;
      margin: 0;
      padding-right: .2rem;
  
      &:hover,
      &:focus {
        opacity: .6;
      }
    }
  }

}

@media (min-width: 600px) {
  .nav {
    &-container {
      text-align: left;
    }

    ul {
      bottom: 0;
      position: absolute;
      right: 0;
    }
  }
}

footer {
  font-family: $serif-secondary;
  padding: 2rem 0;
  text-align: center;

  span {
    color: $default-color;
    font-size: .8rem;
  }
}